import React from "react"
import { Link } from "gatsby"
import { Themed } from "theme-ui"
import Layout from "gatsby-theme-blog/src/components/layout"
import TagList from "../../components/tag-list"

const Posts = ({ location, posts }) => {
  return (
    <Layout location={location} title="Blog">
      {posts.map(({ node }) => (
        <article key={node.id}>
          <header>
            <Themed.h2>
              <Themed.a as={Link} to={node.slug}>
                {node.title}
              </Themed.a>
            </Themed.h2>
            <small>{node.date}</small>
            <TagList tags={node.tags} />
          </header>
          <section>
            <Themed.p>{node.excerpt}</Themed.p>
          </section>
        </article>
      ))}
    </Layout>
  )
}

export default Posts
