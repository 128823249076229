import React from "react"
import { graphql } from "gatsby"
import Posts from "../../gatsby-theme-blog/components/posts"

export const query = graphql`
  query PostsWithTags {
    allBlogPost(sort: { fields: [date, title], order: DESC }, limit: 1000) {
      edges {
        node {
          id
          title
          date(formatString: "YYYY-MM-DD")
          excerpt
          slug
          tags
        }
      }
    }
  }
`

const PostsQuery = ({ data, location }) => {
  return <Posts location={location} posts={data.allBlogPost.edges} />
}

export default PostsQuery
